import { Button, Form, Input, Modal, Select, Space } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type Model from './model';

// 选择群组
export const SelectGroupModal = observer(({ store }: { store: Model }) => {
  if (!store) {
    return null;
  }
  const { getOptions, visible, groupInfoOptions, onClose, saveLoading, onSave, setRef } = store;
  return (
    <Form ref={setRef}>
      <Modal
        centered
        footer={
          <Space>
            <Button onClick={onClose}>取消</Button>
            <Button
              loading={saveLoading}
              onClick={onSave}
              type="primary"
            >
              保存
            </Button>
          </Space>
        }
        maskClosable={false}
        onCancel={onClose}
        open={visible}
        title="录入群组信息"
      >
        <Form.Item
          label="群组名称"
          name="anchorGroupId"
          validateTrigger="onBlur"
          rules={[{ required: true }]}
        >
          <Select
            allowClear
            optionFilterProp="label"
            options={getOptions()}
            placeholder="请选择"
            showSearch
            fieldNames={{
              label: 'label',
              value: 'id',
            }}
          />
        </Form.Item>
        {/* <Form.Item
          label="群内昵称"
          name="anchorGroupNickName"
          validateTrigger="onBlur"
        >
          <Input
            autoComplete="off"
            placeholder="请输入群内昵称"
            maxLength={10}
          />
        </Form.Item> */}
      </Modal>
    </Form>
  );
});
