import type Model from '../../model';
import React from 'react';
import { request } from '../../../../utils';
import type { BaseData } from '../../../../utils';
import type { FormInstance, UploadFile, DescriptionsProps } from 'antd';
import { Image } from 'antd';
import { action, computed, observable } from 'mobx';
import _ from 'lodash';

interface IGroup {
  anchorGroupId: number;
  anchorGroupName: string;
  anchorGroupNickName: string;
  anchorId?: number;
}

interface IOpus {
  anchorId?: number;
  id?: number;
  worksType: string;
  worksUrl: string;
}

export default class AnchorEditModel {
  constructor(parentStore: Model) {
    this.parentStore = parentStore;
  }

  public parentStore: Model;

  @observable public visible = false;

  @observable public anchorId;

  @observable public ref: FormInstance;

  @observable public saveLoading = false;

  @observable public groupInfo: IGroup[] = []; // 群组列表

  @observable public anchorInfo: DescriptionsProps['items'] = [];

  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @observable public opusListVideo = [];

  @observable public opusListImage = [];

  // 打开弹窗
  @action public onShow = async (data?: any) => {
    this.visible = !this.visible;
    if (data) {
      this.anchorId = data.id;
      const req = await request<BaseData<any>>({
        method: 'GET',
        url: '/quan/biz/expert/detail',
        params: { id: data.id },
      });
      req.data.newNoteTypeName = req.data?.dictTypeVOS?.map((item) => item.defName).join('、');
      this.handleAnchorData(req.data);
      this.groupInfo = req.data?.groupInfo || [];
      this.opusListImage = (req.data?.children || [])
        ?.filter((item) => item.worksType === '0')
        ?.map((item) => ({
          uid: _.uniqueId('opusListImage_'),
          status: 'done',
          url: item.worksUrl,
        }));
      this.opusListVideo = await Promise.all(
        (req.data?.children || [])
          ?.filter((item) => item.worksType === '1')
          ?.map((item) => ({
            uid: _.uniqueId('opusListImage_'),
            status: 'done',
            url: item.worksUrl,
            thumbUrl: item.coverUrl,
          })),
      );
    }
  };

  // 关闭弹窗
  @action public onCancel = () => {
    this.visible = false;
    this.groupInfo = [];
    this.anchorId = undefined;
    this.opusListImage = [];
    this.opusListVideo = [];
    this.anchorInfo = [];
  };

  // 添加群组
  @action public addGroup = (group: any) => {
    if (!group) {
      return;
    }
    this.groupInfo.push(group);
  };

  // 预览视频作品
  public previewVideoOpus = (videoUrl: string) => {
    console.log(videoUrl);
  };

  // 处理主播数据展示
  @action public handleAnchorData = (data) => {
    let descriptionsData: any = {
      avatar: '达人头像',
      expertType: '达人类型',
      nickName: '达人昵称',
    };
    if ([1, 2, 4].includes(data.expertType)) {
      descriptionsData = {
        ...descriptionsData,
        expertMainType: '主营类目',
      };
    }
    if ([5].includes(data.expertType)) {
      descriptionsData = {
        ...descriptionsData,
        expertMainTypeValue: '主营类目',
      };
    }
    if ([1, 4].includes(data.expertType)) {
      descriptionsData = {
        ...descriptionsData,
        thirtyDaysSale: '30日橱窗销量',
        thirtyDaysGmv: '30天GMV',
      };
    }
    if ([2].includes(data.expertType)) {
      descriptionsData = {
        ...descriptionsData,
        privateScale: '私域人数',
        privateSaleScale: '销量',
      };
    }
    if ([3].includes(data.expertType)) {
      descriptionsData = {
        ...descriptionsData,
        newNoteTypeName: '笔记类型',
      };
    }
    if ([1, 3, 4, 5].includes(data.expertType)) {
      descriptionsData = {
        ...descriptionsData,
        funNum: '经营平台',
      };
    }
    for (const key in descriptionsData) {
      if (Object.prototype.hasOwnProperty.call(descriptionsData, key)) {
        this.anchorInfo.push({
          key,
          label: descriptionsData[key],
          children: this.dataValueFormat(key, data),
        });
      }
    }
  };

  //主播数据值格式化
  private dataValueFormat = (key, data) => {
    switch (key) {
      case 'avatar': {
        return (
          <div className="avatar">
            <Image
              style={{
                width: '84px',
                height: '84px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              src={data[key]}
              alt="avatar"
            />
          </div>
        );
      }
      case 'expertType': {
        const [value] = this.parentStore.expertTypeOptions.filter((item) => Number(item.value) === Number(data[key]));
        return value?.label;
      }
      case 'expertMainType': {
        const [value] = this.parentStore.expertMainTypeOptions.filter((item) => Number(item.value) === Number(data[key]));
        return value?.label;
      }
      case 'funNum': {
        const funNum = data[key];
        const title = [];
        if (funNum) {
          try {
            JSON.parse(funNum).forEach((item) => {
              const [value] = this.parentStore.platformTypeOptions.filter((_item) => Number(_item.value) === Number(item.platformType));
              title.push(`${value?.label}（粉丝：${item.funNum}，${data.expertType === 5 ? `销量：${item.salesCount}）` : `获赞与收藏：${item.linkAndCollectionCount}）`}`);
            });
          } catch (e) {
            console.error(e);
          }
        }
        return title.join('，');
      }
      default:
        return data[key] || '';
    }
  };
}
