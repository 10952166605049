import type Model from '../../model';
import type { BaseData } from '../../../../utils';
import { request } from '../../../../utils';
import type { FormInstance, UploadFile } from 'antd';
import { message } from 'antd';
import SelectGroupModel from '../selectGroup/model';
import { action, computed, observable, toJS } from 'mobx';
import { API } from '../../api';
import _ from 'lodash';
import moment from 'dayjs';

interface IGroup {
  anchorGroupId: number | string;
  anchorGroupName: string;
  anchorGroupNickName: string;
  anchorId?: number | string;
}

interface IOpus {
  anchorId?: number;
  id?: number;
  worksType: string;
  worksUrl: string;
}

export default class AnchorEditModel {
  constructor(parentStore: Model) {
    this.parentStore = parentStore;
    this.selectGroupStore = new SelectGroupModel({ parent: this });
  }

  @observable public loading = false;

  @observable public loadingSave = false;

  @observable public loadingVideo = false;

  @action
  public setLoadingVideo = () => {
    this.loadingVideo = !this.loadingVideo;
  };

  @observable public selectGroupStore;

  @observable public visible = false;

  @observable public type = 'add';

  @observable public anchorId; //编辑id

  @observable public ref: FormInstance;

  @observable public saveLoading = false;

  @observable public groupInfo: IGroup[] = []; // 群组列表

  @observable
  public expertType: string;

  @action
  public setExpertType = (values: string) => {
    this.ref.resetFields();
    this.expertType = values;
    this.ref.setFieldsValue({ expertType: values });
  };

  @action
  public setGroupInfo = (anchorGroupId: string | number) => {
    this.groupInfo = this.groupInfo.filter((item: any) => item.anchorGroupId !== anchorGroupId);
  };

  @observable public opusListImage: any[] = []; // 图片作品列表

  @observable public opusListVideo: any[] = []; // 视频作品列表

  @action
  public setAddOpusList = (url: string, type: 0 | 1, thumbUrl?: string) => {
    if (type === 0) {
      this.opusListImage = [
        ...this.opusListImage,
        {
          uid: _.uniqueId('opusListImage_'),
          status: 'done',
          url,
        },
      ];
      return;
    }
    this.opusListVideo = [
      ...this.opusListVideo,
      {
        uid: _.uniqueId('opusListVideo_'),
        status: 'done',
        url,
        thumbUrl,
      },
    ];
  };

  @action
  public setDeleteOpusList = (url: string, type: 0 | 1) => {
    if (type === 0) {
      this.opusListImage = this.opusListImage.filter((item: any) => item.url !== url);
      return;
    }
    this.opusListVideo = this.opusListVideo.filter((item: any) => item.url !== url);
  };

  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  // 打开弹窗
  @action public onShow = async (data?: any, type = 'add') => {
    this.visible = !this.visible;
    if (data) {
      this.type = type;
      this.anchorId = data.id;
      const req = await request<BaseData<any>>({
        method: 'GET',
        url: '/quan/biz/expert/detail',
        params: { id: data.id },
      });
      const { avatar, expertType, funNum, children, nickName, expertMainType, thirtyDaysSale, thirtyDaysGmv, privateScale, privateSaleScale, noteTypeIds, noteTopViews, groupInfo, expertMainTypeValue } = req.data || {};
      this.setExpertType(expertType?.toString());
      let _funNum = funNum;
      if (_funNum) {
        _funNum = JSON.parse(_funNum);
      }
      this.ref.setFieldsValue({
        avatar,
        expertType: expertType?.toString(),
        nickName,
        expertMainType: expertMainType?.toString(),
        thirtyDaysSale,
        thirtyDaysGmv,
        privateScale,
        privateSaleScale,
        noteTypeIds,
        noteTopViews,
        expertMainTypeValue,
        funNum: _funNum || [{}],
      });
      this.groupInfo = groupInfo || [];
      this.opusListImage = (children || [])
        ?.filter((item) => item.worksType === '0')
        ?.map((item) => ({
          uid: _.uniqueId('opusListImage_'),
          status: 'done',
          url: item.worksUrl,
        }));
      this.opusListVideo = await Promise.all(
        (children || [])
          ?.filter((item) => item.worksType === '1')
          ?.map((item) => ({
            uid: _.uniqueId('opusListVideo_'),
            status: 'done',
            url: item.worksUrl,
            thumbUrl: item.coverUrl,
          })),
      );
    }
  };

  // 关闭弹窗
  @action public onCancel = () => {
    this.visible = false;
    this.ref?.resetFields();
    this.groupInfo = [];
    this.expertType = undefined;
    this.opusListImage = [];
    this.opusListVideo = [];
    this.type = 'add';
  };

  // 添加群组
  @action public addGroup = (group: any) => {
    if (!group) {
      return;
    }
    this.groupInfo.push(group);
  };

  // 保存主播信息
  @action public onSave = async () => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }

    // if (this.opusListVideo.length + this.opusListImage.length > 6) {
    //   message.error('作品(视频+图片)最多上传6个!');
    //   return;
    // }
    if (this.loadingSave) {
      return;
    }
    this.loadingSave = true;
    // 获取表单值
    const values = this.ref.getFieldsValue();

    const postParams = {
      ...values,
      groupInfo: this.groupInfo,
      children: [],
    };
    const children = [];
    this.opusListImage?.forEach((item, index) => {
      children.push({
        worksType: '0',
        worksUrl: item.url,
        sortFlag: index + 1,
      });
    });
    this.opusListVideo?.forEach((item, index) => {
      children.push({
        worksType: '1',
        worksUrl: item.url,
        coverUrl: item.thumbUrl,
        sortFlag: index + 1,
      });
    });
    postParams.children = children;
    if (this.type === 'edit') {
      postParams.id = this.anchorId;
    }
    if (postParams.funNum) {
      const options = {};
      this.parentStore.platformTypeOptions.forEach((item) => {
        options[item.value] = item.label;
      });
      postParams.funNum = JSON.stringify(
        postParams.funNum?.map((item) => ({
          ...item,
          platformName: options[item.platformType],
          platformIcon: this.parentStore?.platformIconList?.find((icon) => icon.value === item.platformType)?.label,
        })),
      );
    }
    console.log('🚀 ~ AdminEditorModel ~ onSave= ~ postParams:', postParams);
    const apiUrl = this.type === 'add' ? API.saveAnchor : API.editAnchor;
    request<{ code: number; msg?: string; data: any }>({
      url: apiUrl,
      method: 'POST',
      data: { ...postParams },
    })
      .then((result) => {
        message.success(result.msg || '操作成功');
        this.ref.resetFields();
        this.onCancel();
        this.parentStore?.pageStore?.grid?.onQuery();
      })
      .finally(() => {
        this.loadingSave = false;
      });
  };

  public parentStore: Model;

  @action
  public setOpusListImage = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) {
      return;
    }
    this.opusListImage = moveItem(toJS(this.opusListImage), oldIndex, newIndex);
  };

  @action
  public setOpusListVideo = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) {
      return;
    }
    this.opusListVideo = moveItem(toJS(this.opusListVideo), oldIndex, newIndex);
  };

  @action
  public setNoteTypeIds = (value: string[]) => {
    this.ref?.setFieldsValue({ noteTypeIds: value });
  };
}

const moveItem = (arr, fromIndex, toIndex) => {
  if (fromIndex < 0 || fromIndex >= arr.length || toIndex < 0 || toIndex >= arr.length) {
    throw new Error('Index out of bounds');
  }
  const newArr = [...arr];
  const [movedItem] = newArr.splice(fromIndex, 1); // 从 fromIndex 移除元素，并获取该元素
  newArr.splice(toIndex, 0, movedItem);
  return newArr;
};
