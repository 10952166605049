export const API = {
  // 查询达人
  queryAnchorPage: '/quan/biz/expert/list/page',
  // 新增达人
  saveAnchor: '/quan/biz/expert/add',
  // 编辑达人
  editAnchor: '/quan/biz/expert/edit',
  // 删除达人
  deleteAnchor: '/quan/biz/expert/delete',
  // 群组列表
  queryGroupList: '/quan/biz_social_group/applet/group/list',
  //字典接口
  dict: '/quan/sys/dict/type',
};
