import type { BaseData, IMainSubStructureModel, NormalProgrammeParams } from '../../utils';
import { request, SearchListModal, RenderByPermission } from '../../utils';
import { action, observable } from 'mobx';
import { Button, Modal, message } from 'antd';
import React from 'react';
import { API } from './api';
import AnchorEditModel from './modal/anchorEdit/model';
import AnchorDetailModel from './modal/anchorDetail/model';

const { confirm } = Modal;

export default class Model {
  constructor() {
    this.getDictOptions('expert_type');
    // this.getDictOptions('expert_main_type');
    this.getExpertMainTypeOptions();
    this.getDictOptions('platform_type');
    this.getDictOptions('platform_icon');
    this.getNoteType();
    this.pageStore.grid.onQuery();
  }

  public anchorEditStore = new AnchorEditModel(this);

  public anchorDetailStore = new AnchorDetailModel(this);

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        labelWidth: 60,
        type: 'input',
        field: 'id',
        label: '用户ID',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'groupName',
        label: '群昵称',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'nickName',
        label: '姓名',
        placeholder: '请输入',
      },
      {
        type: 'dateRange',
        field: 'createTime',
        label: '录入时间',
      },
      {
        type: 'select',
        field: 'expertType',
        label: '达人分类',
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    collectData: [
      {
        name: '总量',
        value: 0,
      },
    ],
    buttons: [
      {
        text: '删除',
        permissionId: '4',
        handleClick: () => {
          const ids = Array.from(this.pageStore.grid.gridModel.selectedIds);
          if (!ids.length) {
            message.warning('请选择要删除的达人');
            return;
          }
          confirm({
            title: '删除提示',
            content: '是否删除这些达人吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
              this.requestDelete(ids.join(','));
            },
          });
        },
      },
      {
        permissionId: '2',
        text: '新增',
        handleClick: () => {
          this.anchorEditStore.onShow();
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'userId',
          name: '操作',
          width: 200,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_1`}>
                  <Button
                    onClick={() => {
                      this.anchorDetailStore.onShow(row);
                    }}
                    type="link"
                  >
                    详情
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <Button
                    onClick={() => {
                      this.anchorEditStore.onShow(row, 'edit');
                    }}
                    type="link"
                  >
                    编辑
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_4`}>
                  <Button
                    onClick={() => {
                      this.onDelete(row);
                    }}
                    type="link"
                  >
                    删除
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'id',
          name: '用户编号',
        },
        {
          key: 'nickName',
          name: '达人名称',
        },
        {
          key: 'groupInfo',
          name: '所属群组',
          formatter: ({ row }) => {
            const title = row?.groupInfo?.map((item) => `${item.anchorGroupName}(${item.anchorGroupNickName})`);
            return row?.groupInfo?.length ? <div title={title.join(' / ')}>{title.join(' / ')}</div> : '无';
          },
        },
        {
          key: 'expertName',
          name: '分类',
        },
        {
          key: 'worksNum',
          name: '作品',
          formatter: ({ row }) => `${row.children?.length | 0}个`,
        },
        {
          key: 'sysUserName',
          name: '录入人',
        },
        {
          key: 'createTime',
          name: '录入时间',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };

        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        if (postParams.createTime) {
          postParams.createTimeBegin = postParams.createTime.split(',')[0];
          postParams.createTimeEnd = postParams.createTime.split(',')[1];
          delete postParams.createTime;
        }
        return request<BaseData<any>>({
          url: API.queryAnchorPage,
          method: 'POST',
          data: { ...postParams },
        }).then((res) => {
          this.pageStore.grid.collectData = [
            {
              name: '总量',
              value: res?.data?.total,
            },
          ];
          return res;
        });
      },
    },
  };

  public pageStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  // 删除表格项
  private onDelete = (row) => {
    confirm({
      title: '删除提示',
      content: `是否删除「${row.nickName}」？`,
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        this.requestDelete(row.id);
      },
    });
  };

  // 请求删除接口
  private requestDelete = async (ids) => {
    const result = await request<{ code: number; msg?: string; data: any }>({
      url: API.deleteAnchor,
      method: 'POST',
      data: { ids },
    });
    message.success(result.msg || '删除成功');
    this.pageStore.grid.onQuery();
  };

  @observable public expertTypeOptions = []; //达人类型

  @observable public expertMainTypeOptions = []; //主营类目

  @observable public platformTypeOptions = []; //经营平台

  @observable public platformIconList = []; // 经营平台图标

  @observable public noteTypeOptions = []; //笔记类型

  @action
  public getDictOptions = async (dictType: string) => {
    const req = await request<any>({
      method: 'GET',
      url: API.dict,
      params: { dictType },
    });
    const data = (req?.data || [])?.map((item) => ({
      label: item.dictName,
      value: item.dictValue,
    }));
    switch (dictType) {
      case 'expert_type': {
        this.expertTypeOptions = data;
        this.pageStore.programme.filterItems.addDict({ expertType: data });
        break;
      }
      case 'expert_main_type': {
        this.expertMainTypeOptions = data;
        break;
      }
      case 'platform_type': {
        this.platformTypeOptions = data;
        break;
      }
      case 'platform_icon': {
        this.platformIconList = data;
        break;
      }
    }
  };

  @action
  public getNoteType = async () => {
    const req = await request<any>({
      method: 'POST',
      url: '/quan/biz/dict/list',
      data: { defType: 'note_type' },
    });
    const data = (req?.data || [])?.map((item) => ({
      label: item.defName,
      value: item.id,
    }));
    this.noteTypeOptions = data;
  };

  @action
  public getExpertMainTypeOptions = async () => {
    const req = await request<any>({
      method: 'POST',
      url: '/quan/biz/dict/list',
      data: { defType: 'expert_main_type' },
    });
    const data = (req?.data || [])?.map((item) => ({
      label: item.defName,
      value: item.id,
    }));
    this.expertMainTypeOptions = data;
  };
}
